import React from 'react'
import styled from '@emotion/styled'
import { elements } from '../theme'
import Icon from "./Icon";

export default function Footer() {
  return (
    <FooterContainer>
      <Nav>
        <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Header").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Inicio
          </a>
        <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Servicios").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Proyectos
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Nosotros").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Sobre Nosotros
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Clientes").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Clientes
          </a>
      </Nav>
      <SocialMedia>
        <Icon link={"https://www.instagram.com/movimientosyserviciosrosario"} icon={"instagram"} size={"38px"} withBorder></Icon>
        <Icon link={"https://www.x.com"} icon={"twitter"} size={"38px"} withBorder></Icon>
        <Icon link={"https://www.youtube.com"} icon={"youtube"} size={"38px"} withBorder></Icon>
        <Icon link={"https://wa.me/5493418103333"} icon={"whatsapp"} size={"38px"} withBorder></Icon>
        <Icon onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth'})} icon={"arrow-up"} size={"38px"} withBorder></Icon>
      </SocialMedia>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  width: 100%;
  background-color: ${elements.footerBackground};
  padding: 40px 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  img {
    filter: brightness(1000) grayscale(1000);
    width: 300px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 6vw;
  }
  `

const Nav = styled.div`
  a {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: ${elements.footerFont};
    &:not(:last-child) {
      margin-right: 32px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
`

const SocialMedia = styled.div`
  display: flex;
  gap: 16px;
  & button:last-of-type{
    margin-left: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    justify-content: flex-start;
  }
`
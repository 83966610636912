import React from 'react'
import Slider from "react-slick";
import styled from '@emotion/styled'

export default function CompaniesSlider(props) {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: props.itemCount,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {props.items.map((item, index) => {
          return (
            <SliderItem key={index}>
              <img 
                src={item.logoUrl} 
                alt={item.name} 
                />
            </SliderItem> 
          )
        })}
      </Slider>
    </SliderContainer>
    )
}

const SliderContainer = styled.div`
  position: relative;
  width: 100vw;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-self: center;

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    background: hsla(0, 0%, 100%, 1);
    background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    background: -moz-linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    background: -webkit-linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    z-index: 99;
    @media only screen and (max-width: 1024px) {
      width: 150px;
    }
  }
  &::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 400px;
    background: hsla(0, 0%, 100%, 1);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    background: -moz-linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    background: -webkit-linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 20%, hsla(0, 0%, 100%, 1) 20%, transparent 100%);
    z-index: 99;
    @media only screen and (max-width: 1024px) {
      width: 150px;
    }
  }
`

const SliderItem = styled.div`
  height: 160px;
  display: flex!important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    max-width: 120px;
  }
  @media only screen and (max-width: 1024px) {
    height: 130px;
    img {
      max-width: 80px;
    }
  }
`

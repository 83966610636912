import React from 'react'
import styled from '@emotion/styled'
import { elements } from '../theme'

export default function Card({ title, text, img }) {
  return (
    <CardWrapper>
      <ImageContainer>
        <img src={img} alt="" />
      </ImageContainer>
      <CardTitle>
        {title}
      </CardTitle>
      <CardText>
        {text}
      </CardText>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 380px;
  max-width: 310px;
  width: 100%;
  background-color: ${elements.CardBackground};
  flex: 1 0 31%;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s;
  user-select: none;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transform: translateY(-3px);
  }
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`

const ImageContainer = styled.div`
  height: 170px;
  border-bottom: 5px solid ${elements.CardAccent};
  overflow: hidden;
  img{
    -webkit-user-drag: none;
    width: 100%;
  }
`

const CardTitle = styled.h4`
  color: ${elements.CardTitle};
  margin: 25px 35px 28px 35px;
  text-align: center;
  text-transform: uppercase;
  font-size: 19px;
`

const CardText = styled.p`
  color:  ${elements.CardText};
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  padding: 0 30px;
`


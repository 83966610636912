import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { elements, spaces } from '../theme'
import useContentful from '../hooks/useContentful';
import useOnScreen from '../hooks/useOnScreen';



export default function AboutUs() {
  const { videoSectionVideo } = useContentful();
  // const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const videoRef = useRef(null)
  const entry = useOnScreen(videoRef, {})
  const videoIsVisible = !!entry?.isIntersecting

  React.useEffect(() => {
    if (videoIsVisible) {
      videoRef.current.play()
    } else {
      // videoRef.current.pause()
    }
  }, [videoIsVisible])


  return (
    <>
      <Container>
        {/* <PlayButtonWrapper 
          isVisible={!videoIsPlaying} 
          onClick={() => {
            setVideoIsPlaying(!videoIsPlaying);
            videoRef.current.play();
          }}
        >
          <svg viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.21819 1.3221C4.45902 -0.301166 0.980469 1.68824 0.980469 4.8895V23.1105C0.980469 26.3116 4.45871 28.3011 7.21789 26.6781L22.7052 17.5685C25.4257 15.9683 25.4259 12.034 22.7055 10.4335L7.21819 1.3221Z" fill="white"/>
          </svg>
        </PlayButtonWrapper> */}
          {videoSectionVideo.videoUrl && (
            <video
              ref={videoRef}
              id="my-video"
              className="video-js"
              width={vw > 1024 ? "100%" : ""}
              height={vw > 1024 ? "" : "100%"}
              preload="auto"
              data-setup="{}"
              playsInline autoPlay="autoplay" muted loop
            >
              <source src={videoSectionVideo.videoUrl} type="video/mp4" />
            </video>
          )}
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  gap: 180px;
  align-items: center;
  background-color: ${elements.AboutUsBackground};
  video {
    cursor: pointer;
  }
  @media only screen and (max-width: 1024px) {
    height: 70vh;
  }
`

const PlayButtonWrapper = styled.div`
  display: ${props => props.isVisible ? "flex" : "none"};
  position: absolute;
  z-index: 99;
  margin: 25% auto;
  border-radius: 50%;
  width: 134px;
  height: 134px;
  background-color: rgb(188, 186, 205, 58%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  &:hover{
    background-color: rgba(0, 0, 0, .5);
  }
  svg {
    width: 34px;
  }
`
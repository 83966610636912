import React from 'react'
import Slider from "react-slick";
import styled from '@emotion/styled'
import { useState, useRef } from 'react'
import { colors, elements, spaces } from '../theme'

export default function BackgroundSlider(props) {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    cssEase: "ease-out",
    pauseOnHover: false,
    rows: 1,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex)
      setIsSliding(true)
    },
    afterChange: () => {
      setIsSliding(false)
    },
  };

  return (
    <>
    <SliderContainer>
      <Slider ref={sliderRef} {...settings}>
        {props.imageUrlList.map((imageUrl, index) => {
          return (
            <SliderItem viewportWidth={vw} key={index}>
              <img 
                src={imageUrl} 
                />
            </SliderItem> 
          )
        })}
      </Slider>
      </SliderContainer>
      <RadioGroup>
        <Radio 
          onClick={() => {
            if (isSliding) {
              return
            }
            setCurrentSlide(0)
            sliderRef.current.slickGoTo(0)
            setIsSliding(true)
            setTimeout(() => {
              setIsSliding(false)
            }, 1001);
          }} 
          role={"radio"} 
          selected={currentSlide === 0}
        />

        <Radio 
          onClick={() => {
            if (isSliding) {
              return
            }
            setCurrentSlide(1)
            sliderRef.current.slickGoTo(1)
            setIsSliding(true)
            setTimeout(() => {
              setIsSliding(false)
            }, 1001);
          }} 
          role={"radio"} 
          selected={currentSlide === 1}
        />

        <Radio 
          onClick={() => {
            if (isSliding) {
              return
            }
            setCurrentSlide(2)
            sliderRef.current.slickGoTo(2)
            setIsSliding(true)
            setTimeout(() => {
              setIsSliding(false)
            }, 1001);
          }} 
          role={"radio"} 
          selected={currentSlide === 2}
        />
      </RadioGroup>  
      </>
    )
}

const SliderContainer = styled.div`
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 0;
  overflow: hidden;
`

const SliderItem = styled.div`
  height: 100%;
  width: 100%;
  display: flex!important;
  overflow: hidden;
  img {
    width: ${props => (props.viewportWidth > 1024) ? "100%" : ""};
    height: ${props => (props.viewportWidth > 1024) ? "" : "100%"};
  }
`

const RadioGroup = styled.div`
  position: absolute;
  height: 100px;
  width: 84%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -12px;
  left: 8%;
  z-index: 999;
  background-clip:padding-box;
`

const Radio = styled.div`
  width: 33.3333333%;
  height: 4px;
  border: 0.09em solid rgba($color: #000000, $alpha: 0.3);
  cursor: pointer;
  background-color: ${props => props.selected ? colors.secondary : "rgba(256, 256, 256, 0.66)"};
  margin-right: 18px;
  transition: all 0.3s; 
  clip-path: border-box;
`
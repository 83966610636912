import React from 'react'
import styled from '@emotion/styled'
import Card from "../components/Card";
import Subtitle from "../components/Subtitle";
import Title from "../components/Title";
import { elements, spaces } from '../theme'

export default function Services() {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  return (
    <>
      <a id="Servicios"/>
      <Container>
          <Subtitle color={elements.SubtitleFont}>Nuestros Servicios</Subtitle>
          <Title color={elements.TitleFont}>Encontrá el servicio ideal para vos</Title>
          <CardsContainer>
            <Card 
              title={"Movimiento de suelo"} 
              text={"Movimiento de suelo, canalización."}
              img={'./img/servicios/movimientos-de-suelo.jpeg'}
            />
            <Card 
              title={"Desarrollo urbano"} 
              text={"Construcción de lotes llave en mano."}
              img={'./img/servicios/desarrollo-urbano.jpg'}
            />
            <Card 
              title={"venta de suelos selecionado"} 
              text={"Venta de suelo, tosca, tierra negra, tierra de relleno, estabilizado granular."}
              img={'./img/servicios/venta-de-suelos.jpeg'}
            />
            <Card 
              title={"PISOS INDUSTRIALES & CERCOS PERimeTRALES"} 
              text={"Pisos industriales y de calzada, veredas, rampa para discapacitados, cerco perimetral de hormigón amrado."}
              img={'./img/servicios/pisos-industriales.jpeg'}
            />
            <Card 
              title={"Obras hidrulicas"} 
              text={"Obras Pluviales, agua potable y red cloacales."}
              img={'./img/servicios/obras-hidraulicas.jpeg'}
            />
            <Card 
              title={"ALQUILERES DE EQUIPOS VIALES"} 
              text={"Alquiler de equipos viales, camiones 6x4, batea, carretones, camión hidrogrúa y autoelevador."}
              img={'./img/servicios/alquiler-de-equipos.jpeg'}
            />
          </CardsContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 80px ${spaces.horizontalPadding};
  background-color: ${elements.ServicesBackground};
  @media only screen and (max-width: 1024px) {
    padding: 30px ${spaces.mobileHorizontalPadding};
  }
`

const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 40px;
  margin-top: 40px;
  justify-items: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`
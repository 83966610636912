export const colors = {
  black: "black",
  white: "#FFFFFF",
  gray1: "#D0D0D0",
  gray2: "#F1F1F1",
  gray3: "#7B7B7B",
  grayBackground: "#",
  primary: "#04074E",
  primaryLight: "#1E285F",
  secondary: "#F1CF00",
  secondaryBright: "#FFE963",
  disabledBackground: "#DDDDDD",
  disabledFont: "#939393"
}

export const elements = {
  headerBackground: colors.primary,
  headerFont: colors.white,

  title: colors.black,
  titleUnderline: colors.secondary,

  subtitleFont: colors.gray3,
  subtitleUnderline: colors.secondary,

  footerBackground: colors.white,
  footerFont: colors.primaryLight,
  footerIcon: colors.primary,

  AboutUsBackground: colors.primary,
  AboutUsText: colors.gray1,
  AboutUsCTABackground: colors.secondary,
  AboutUsCTABackgroundHover: colors.secondaryBright,
  AboutUsCTAText: colors.black,
  AboutUsCTATitleFont: colors.white,
  AboutUsCTASubtitleFont: colors.gray1,

  ServicesBackground: colors.white,
  ServicesText: colors.gray1,

  HeroTitle: colors.white,
  HeroText: colors.white,
  HeroAccent: colors.secondary,

  CardBackground: colors.gray2,
  CardTitle: colors.black,
  CardText: colors.gray3,
  CardAccent: colors.secondary,
}

export const spaces = {
  horizontalPadding: "8vw",
  mobileHorizontalPadding: "4vw"
}
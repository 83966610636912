import { useEffect, useState, useMemo } from 'react'

const contentful = require("contentful");

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

const useContentful = () => {
  const [companyLogos, setCompanyLogos] = useState([])
  const [heroSliderImages, setHeroSliderImages] = useState([])
  const [videoSectionVideo, setVideoSectionVideo] = useState([])
  const [heroVideo, setHeroVideo] = useState([])
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  useEffect(() => {
    getCompanyLogos();
    getHeroSliderImages();
    getVideo();
    getHeroVideo();
  }, [])


  const getCompanyLogos = async () => {
    try {
      const logos = await client.getEntries({
        content_type: "company",
        select: "fields"
      }).then((response) => {
        const items = response.items.map((item) => {
          return {
            name: item.fields.name,
            logoUrl: item.fields.logo.fields.file.url
          }
        })
        return items
      })
      setCompanyLogos(logos)
      return logos
    } catch (error) {
      console.log(error)
    }
  }

  const getHeroSliderImages = async () => {
    try {
      const images = await client.getEntries({
        content_type: "heroSliderImages",
        select: "fields"
      }).then((response) => {
        const items = response.items[0].fields.images.map((image) => {
          return image.fields.file.url
        })
        return items
      })
      setHeroSliderImages(images)
      return images
    } catch (error) {
      console.log(error)
    }
  }

  const getVideo = async () => {
    try {
      const video = await client.getEntries({
        content_type: "videoSectionVideo",
        select: "fields"
      }).then((response) => {
        return {
          posterUrl: response.items[0].fields.poster.fields.file.url,
          videoUrl: response.items[0].fields.video.fields.file.url
        }
      })
      setVideoSectionVideo(video)
    } catch (error) {
      console.log(error)
    }
  }
  
  const getHeroVideo = async () => {
    try {
      const video = await client.getEntries({
        content_type: "heroVideo",
        "fields.viewport": vw > 1024 ? "desktop" : "mobile",
        select: "fields"
      }).then((response) => {
        return {
          videoUrl: response.items[0].fields.video.fields.file.url
        }
      })
      setHeroVideo(video)
    } catch (error) {
      console.log(error)
    }
  }
  return {
    companyLogos,
    heroSliderImages,
    videoSectionVideo,
    heroVideo
  }
}

export default useContentful;
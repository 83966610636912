import React from 'react'
import styled from '@emotion/styled'
import Button from "../components/Button";
import BackgroundSlider from "../components/BackgroundSlider";
import { colors, elements, spaces } from '../theme'
import useContentful from '../hooks/useContentful';


export default function Hero() {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const { heroVideo } = useContentful();
  
  return (
    <>
      <a id="Inicio"/>
      <Container>
        {heroVideo.videoUrl && (
          <video
            id="my-video"
            className="video-js"
            width="100%"
            preload="auto"
            data-setup="{}"
            playsInline autoPlay="autoplay" muted loop
          >
            <source src={heroVideo.videoUrl} type="video/mp4" />
          </video>
        )}
        <DarkOverlay />
        <MainTitle>
          Movimientos & Servicios S.R.L.
        </MainTitle>
        <HeroText>
          Somos una empresa que nos especializamos en el movimiento de suelo para industrias y desarrollos urbanos.
        </HeroText>
        <Button onClick={() => document.getElementById("Contacto").scrollIntoView({behavior: 'smooth'})}>Hablemos!</Button>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position-y: -90px;
  height: calc(100vh - 110px);
  gap: 24px;
  padding: 0 ${spaces.horizontalPadding};
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    padding: 0 ${spaces.mobileHorizontalPadding};
    height: calc(100svh - 90px);
  }
  video{
    position: absolute;
    z-index: 1;
  }
`

const DarkOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #00000099;
  z-index: 1;
`

const MainTitle = styled.h1`
  color: ${elements.HeroTitle};
  text-transform: uppercase;
  font-size: 52px;
  text-align: center;
  max-width: 100vw;
  @media only screen and (max-width: 1024px) {
    font-size: 38px;
  }
`

const HeroText = styled.p`
  color: ${elements.HeroText};
  margin-bottom: 60px;
  font-size: 20px;
  text-align: center;
  line-height: 28px;
  max-width: 100vw;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    max-width: 60ch;
  }
`

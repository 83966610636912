import styled from '@emotion/styled'
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutUs from "./sections/AboutUs";
import Services from "./sections/Services";
import Hero from "./sections/Hero";
import Contact from "./sections/Contact";
import TheyTrustUs from "./sections/TheyTrustUs";
import Video from "./sections/Video";
import useContentful from './hooks/useContentful';
import { useEffect } from 'react';
import WhatsappFloatingButton from './components/WhatsappFloatingButton';

export default function Homepage() {
  const { companyLogos } = useContentful();
  // const html = document.getElementsByTagName('body').item(0);

  // useEffect(() => {
  //     console.log("something");
  //     window.addEventListener("scroll", () => {
  //       console.log("scrolling");
  //       if (html.scrollTop > 400) {
  //         console.log("Appear");
  //       }
  //       if(html.scrollTop < 399) {
  //         console.log("Disappaer");
  //       }}
  //     )
      
  // }, [])


  return (
    <>
      <Header></Header>
      <PageContent>
        <WhatsappFloatingButton />
        <Hero />
        <Services />
        <AboutUs />
        <TheyTrustUs companyLogos={companyLogos} />
        <Video />
        <Contact />
      </PageContent>
      <Footer></Footer>
    </>
  );
}

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`
import React from 'react'
import styled from '@emotion/styled'
import { elements } from '../theme'

export default function Icon({ icon, size, withBorder, link, onClick }) {
  return (
    <IconContainer 
      size={size} 
      withBorder={withBorder} 
      onClick={() => {
        link ? window.open(link, "_blank") : onClick()
      }}
    >
      {icon === "twitter" && (
        <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.09091 0H0L6.00727 7.7098L0.327273 14H2.25455L6.90036 8.8557L10.9091 14H16L9.73964 5.9654L15.1273 0H13.2L8.84655 4.8202L5.09091 0Z" fill={elements.footerIcon} />
        </svg>
      )}
      {icon === "instagram" && (
        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V13C0 15.7614 2.23858 18 5 18H13C15.7614 18 18 15.7614 18 13V5C18 2.23858 15.7614 0 13 0H5ZM14 5C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3C13.4477 3 13 3.44772 13 4C13 4.55228 13.4477 5 14 5Z" fill={elements.footerIcon} />
          <circle cx="9" cy="9" r="3.5" stroke="white"/>
        </svg>
      )}
      {icon === "youtube" && (
        <svg viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 7.75138C1 4.69328 1 3.1637 1.9955 2.18025C2.9921 1.19575 4.5607 1.15349 7.6968 1.06793C9.1818 1.02779 10.6998 0.999268 12 0.999268C13.2991 0.999268 14.8171 1.02779 16.3032 1.06793C19.4393 1.15349 21.0079 1.19575 22.0034 2.18025C23 3.1637 23 4.69434 23 7.75138V9.2461C23 12.3053 23 13.8338 22.0045 14.8183C21.0079 15.8017 19.4404 15.845 16.3032 15.9295C14.8182 15.9707 13.3002 15.9993 12 15.9993C10.5653 15.9951 9.1308 15.9719 7.6968 15.9295C4.5607 15.845 2.9921 15.8028 1.9955 14.8183C1 13.8338 1 12.3042 1 9.24715V7.75138Z" fill={elements.footerIcon}  stroke={elements.footerIcon}  strokeWidth="1.5"/>
          <path d="M14.0418 8.41148C14.111 8.44945 14.111 8.54885 14.0418 8.58682L10.4997 10.5306C10.433 10.5671 10.3516 10.5189 10.3516 10.4429V6.55542C10.3516 6.4794 10.433 6.43118 10.4997 6.46775L14.0418 8.41148Z" fill="white"/>
        </svg>
      )}
      {icon === "arrow-up" && (
        <svg viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 3.46967C11.7626 3.17678 12.2374 3.17678 12.5303 3.46967L18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303C18.2374 10.8232 17.7626 10.8232 17.4697 10.5303L12.75 5.81066L12.75 20C12.75 20.4142 12.4142 20.75 12 20.75C11.5858 20.75 11.25 20.4142 11.25 20L11.25 5.81066L6.53033 10.5303C6.23744 10.8232 5.76256 10.8232 5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967L11.4697 3.46967Z" fill="#1C274C"/>
        </svg>
      )}
      {icon === "whatsapp" && (
        <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <g transform="translate(-300.000000, -7599.000000)" fill={elements.footerIcon}>
            <g transform="translate(56.000000, 160.000000)">
              <path d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439"></path>
            </g>
        </g>
      </svg>
      )}
    </IconContainer>
  )
}

const IconContainer = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  width: ${props => props.size};
  height: ${props => props.size};
  border: ${props => props.withBorder ? `2px solid ${elements.footerIcon}` : "unset"};
  border-radius: 4px;
  transition: all 0.2s;
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    opacity: 60%;
  }
  svg {
    height: ${props => props.withBorder ? "53%" : props.size};

  }
`

import React from 'react'
import styled from '@emotion/styled'
import Title from "../components/Title";
import CompaniesSlider from "../components/CompaniesSlider";
import { colors } from '../theme'

export default function TheyTrustUs(props) {
  
  return (
    <Container>
      <a id="Clientes"/>
      <Title color={colors.primary} centered>Clientes que confiaron en nosotros</Title>
      {props.companyLogos ? (
        <CompaniesSlider 
          items={props.companyLogos}
          itemCount={7}
          mobileRows={3}
        />
        ) : (<></>)
      }
    </Container>
  )
}

const Container = styled.div`
  padding: 100px 0;
  @media only screen and (max-width: 1024px) {
    padding: 60px 0 40px;
  }
`

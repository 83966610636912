import React from 'react'
import styled from '@emotion/styled'
import { elements } from '../theme'

export default function Title({ children, color, centered}) {
  return (
    <TitleContainer color={color} centered={centered}>
      {children}
    </TitleContainer>
  )
}

const TitleContainer = styled.h2`
  color: ${props => props.color || elements.TitleFont};
  font-size: 38px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: ${props => props.centered ? "center" : "left"};
  @media only screen and (max-width: 1024px) {
    font-size: 32px;
  }
`
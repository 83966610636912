import { css } from '@emotion/react'

export const GlobalCss = css`
  @import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@100;300;400;500;600;700&family=Poppins:wght@300;400;500;700&family=Roboto+Condensed:wght@300;400;500;600;700;800&display=swap');
  * {
    font-family: 'Roboto Condensed', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    box-sizing: border-box;
    z-index: 10;
  }
  html {
    overflow-x: clip;
    max-width: 100vw;
  }
  body {
    width: 100%;
    overflow-x: clip;
    display: flex;
    justify-content: center;
    /* overflow-x: hidden; */
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #root{
    width: 100%;
    overflow-x: clip;
  }

  @keyframes headerToggle {
    0% {
    position: "relative";
    }
    1% {
      position: "sticky";
      transform: translateY(-110px);
    }
    100% {
      position: "sticky";
      transform: translateY(0px);
    } 
  }

  .navIsSticky {
    animation: headerToggle 1s ease forwards;
  }
`
import { useState, useRef } from 'react';
import styled from '@emotion/styled'
import emailjs from '@emailjs/browser';
import { colors, elements, spaces } from '../theme'

export default function ContactForm() {
  const formRef = useRef(null);

  const [templateParams, setTemplateParams] = useState({ 
    form_name : "",
    form_lastName : "",
    form_company : "",
    form_email : "",
    form_message : "",
  })

  // Email.JS Credentials
  const formConfig = {
    serviceID : process.env.REACT_APP_EMAILJS_SERVICE_ID,
    templateID : process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    publicKey : process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
  }

  const handleChange = (e) => {
    setTemplateParams({
      ...templateParams,  
      [e.target.name] : e.target.value
    })
  }

  const handleSubmit = async () => {
    if(validateForm()){
      console.log(formConfig.serviceID, formConfig.templateID, templateParams, formConfig.publicKey);
      emailjs.send(formConfig.serviceID, formConfig.templateID, templateParams, formConfig.publicKey)
      .then((result) => {
          console.log(result.text);
          alert("Gracias por contactarte");
          // window.location.reload(false);
          window.location.reload()
      }, (error) => {
          console.log(error.text);
      });
    } else {
      console.log("Error en el formulario");
      return;
    }
  }

  const validateForm = () => {
    // Nombre
    if (templateParams.form_name.length < 3) {
        return false;
    }
    // Mail
    if ((templateParams.form_email.length < 5) || !templateParams.form_email?.includes("@") || !templateParams.form_email?.includes(".com")) {
        return false;
    }
    return true;
  }

  return (
    <FormContainer>
      <h3>Formulario de contacto</h3>
      <Form id="contact-form" autoComplete="off" ref={formRef}>
        {/* Nombre */}
        <FormItem className="form_name" >
          <label htmlFor="form_name">Nombre*</label>
          <input 
            id="form_name" 
            type="text" 
            name="form_name" 
            pattern="[A-Za-z0-9]+" 
            maxLength="20"
            value={templateParams.form_name}
            onChange={(e) => handleChange(e)}
          />
        </FormItem>

        {/* Apellido */}
        <FormItem className="form_lastName" >
          <label htmlFor="form_lastName">Apellido</label>
          <input 
            id="form_lastName" 
            type="text" 
            name="form_lastName" 
            pattern="[A-Za-z0-9]+" 
            maxLength="20"
            value={templateParams.form_lastName}
            onChange={(e) => handleChange(e)}
          />
        </FormItem>

        {/* Empresa */}
        <FormItem className="form_company" >
          <label htmlFor="form_company">Empresa</label>
          <input 
            id="form_company" 
            type="text" 
            name="form_company" 
            pattern="[A-Za-z0-9]+" 
            maxLength="20"
            value={templateParams.form_company}
            onChange={(e) => handleChange(e)}
          />
        </FormItem>

        {/* Email */}
        <FormItem className="form_email">
          <label htmlFor="form_email">Email*</label>
          <input 
            id="form_email" 
            type="email" 
            name="form_email"
            value={templateParams.form_email}
            onChange={(e) => handleChange(e)}
          />
        </FormItem>
    
        {/* Mensaje */}
        <FormItem className="form_message" fullWidth>
          <label htmlFor="form_message">Mensaje</label>
          <textarea 
            style={{ resize: "none", height: "100px" }}
            maxLength="800"
            id='form_message'
            name="form_message" 
            value={templateParams.message}
            onChange={(e) => handleChange(e)}
          ></textarea>
        </FormItem>

        {/* Dummy Submit */}
        <SubmitButton disabled={!templateParams.form_name || !templateParams.form_email || !templateParams.form_message || !validateForm()} type="button" className="dummy_submit" onClick={() => handleSubmit()}>Enviar</SubmitButton>
      </Form>
    </FormContainer>
  )
}

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 50px;
  background-color: white;
  width: fit-content;
  h3 {
    color: ${colors.primary};
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 2px;
    margin-bottom: 18px;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 10px;
    width: 90vw;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 460px;
  gap: 20px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    margin-left: 6px;
    font-size: 14px;
  }
  input, textarea{
    height: 40px;
    margin-top: 2px;
    font-size: 14px;
    border: none;
    outline: none;
    background-color: ${colors.gray2};
    border: 1px solid ${colors.gray1};
    padding: 0 6px;
    color: black;
    border-radius: 4px;
  }
  @media only screen and (min-width: 1025px) {
    grid-column: ${props => props.fullWidth? "span 2" : ""};
    width: ${props => props.fullWidth? "100%" : ""};
  }
`

export const SubmitButton = styled.button`
  border: none;
  background-color: ${colors.secondary};
  color: black;
  text-transform: uppercase;
  width: 100%;
  height: 46px;
  padding: 5px 0;
  font-size: 16px;
  margin-top: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s;
  &:hover{
    cursor: pointer;
    background-color: ${colors.secondaryBright};
  }
  &:disabled{
    background-color: ${colors.disabledBackground};
    color: ${colors.disabledFont};
    cursor: not-allowed;
  }
`
import React from 'react'
import styled from '@emotion/styled'
import ContactForm from "../components/ContactForm";
import { colors, elements, spaces } from '../theme'

export default function Contact() {
  return (
    <>
      <a id="Contacto"/>
      <Content>
      <BlueOverlay />
        <ContactForm>
        </ContactForm>
      </Content>
    </>
  )
}

const Content = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url(./img/contact.jpg);
  background-size: cover;
  padding: 80px 0;
  @media only screen and (max-width: 800px) {
    padding: 120px ${spaces.mobileHorizontalPadding};
  }
`

const BlueOverlay = styled.div`
  position: absolute;
  background-color: ${`${colors.primary}cc`};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
`
import styled from '@emotion/styled'
import { useState, useEffect, useRef } from 'react'
import { elements, spaces } from '../theme'
import { css, keyframes } from '@emotion/react'

const Header = () => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const [headerPosition, _setHeaderPosition] = useState("relative")
  const hedaerPositionRef = useRef(headerPosition)

  // the reason of this is that I need the onScroll event to be different 
  // depending on the position attribute of the header
  const setHeaderPosition = newPosition => {
    hedaerPositionRef.current = newPosition;
    _setHeaderPosition(newPosition);
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html').item(0);
    window.addEventListener("scroll", 
      () => {
        setMobileNavIsOpen(false)
        if (hedaerPositionRef.current === "relative" && html.scrollTop > 180) {
          setHeaderPosition("sticky")
        }
        if (hedaerPositionRef.current === "sticky" && html.scrollTop === 0) {
          setHeaderPosition("relative")
        }
      }
    )
  }, [])

  return (
    <>
    <>
      <a id="Header"/>
        <HeaderInner headerIsSticky={headerPosition === "sticky"} mobileNavIsOpen={mobileNavIsOpen}>
          <MobileNavButton onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}>
            <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6.00092H21M3 12.0009H21M3 18.0009H21" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </MobileNavButton>
          <Logo />
          <Nav>
            <a onClick={(e) => {
              e.preventDefault();
              document.getElementById("Servicios").scrollIntoView({behavior: 'smooth'})}} 
              href="#"
            >
              Proyectos
            </a>
            <a onClick={(e) => {
              e.preventDefault();
              document.getElementById("Nosotros").scrollIntoView({behavior: 'smooth'})}} 
              href="#"
            >
              Sobre Nosotros
            </a>
            <a onClick={(e) => {
              e.preventDefault();
              document.getElementById("Clientes").scrollIntoView({behavior: 'smooth'})}} 
              href="#"
            >
              Clientes
            </a>
            <a onClick={(e) => {
              e.preventDefault();
              document.getElementById("Contacto").scrollIntoView({behavior: 'smooth'})}} 
              href="#"
            >
              Contactanos
            </a>
          </Nav>
        </HeaderInner>
    </>
      {vw <= 1024 && (
        <MobileNav isOpen={mobileNavIsOpen} headerIsSticky={headerPosition === "sticky"}>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Servicios").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Proyectos
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Nosotros").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Sobre Nosotros
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Clientes").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Clientes
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("Contacto").scrollIntoView({behavior: 'smooth'})}} 
            href="#"
          >
            Contactanos
          </a>
        </MobileNav>
      )}
    </>
  )
}

export default Header

const toggleHeader = keyframes`
  0% {
    position: relative;
  }
  1% {
    position: sticky;
    transform: translateY(-110px);
  }
  100% {
    position: sticky;
    transform: translateY(0px);
  }
`

const HeaderInner = styled.div`
  position: relative;
  animation: ${props => props.headerIsSticky ? css`${toggleHeader} 500ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards` : ""};
  left: 0;
  top: 0;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spaces.horizontalPadding};
  background-color: white;
  z-index: 999;
  background-color: ${elements.headerBackground};
  box-shadow: ${props => !props.mobileNavIsOpen ? "0px 1px 10px rgba(0,0,0, 0.4)" : ""};
  transition: all 0.3s;
  @media only screen and (max-width: 1024px) {
    padding: 0 8%;
    justify-content: flex-start;
    height: 90px;
  }
`

const Nav = styled.div`
  a {
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    color: ${elements.headerFont};
    &:not(:last-child) {
      margin-right: 32px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const MobileNav = styled.div`
  position: ${props => props.headerIsSticky ? "fixed" : "absolute"};
  display: flex;
  flex-direction: column;
  height: 124px;
  left: 0;
  top: 0;
  background-color: ${elements.headerBackground};
  width: 100%;
  padding: 0px 50px 20px;
  z-index: 99;
  transition: all 0.3s;
  transform: ${props => !props.isOpen ? "translateY(-125px)" : "translateY(90px)"};
  a {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`

const Logo = styled.div`
  height: 54px;
  width: 150px;
  background-image: url("./img/logo.jpg");
  background-size: contain;
  background-repeat: no-repeat;
`

const MobileNavButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 4%;
  @media only screen and (min-width: 800px) {
    display: none;
  }
`